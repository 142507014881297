import {bindable} from "aurelia-templating";
import {ModalCaveAdd} from "../../resources/elements/modal-cave-add";
import {autoinject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {UserService} from "../../resources/services/UserService";
import {FhirService} from "../../resources/services/FhirService";
import {DialogMessages} from "../../resources/services/DialogMessages";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";

@autoinject
export class CaveViewRow {
    @bindable items : any[];
    @bindable patient : PatientItem;
    constructor(protected dialogService : DialogService, protected  i18n : I18N) {
    }

    async updateItem(item, caveAdd: ModalCaveAdd) {
        item.isLoading = true;
        // update item in the ui
        item.text = caveAdd.text
        item.title = caveAdd.title;
        item.performer = UserService.UserName;
        item.when = caveAdd.targetDate;

        // update the observation
        item.observation.text = {
            status: 'generated',
            div: `<div xmlns="http://www.w3.org/1999/xhtml"><div><b>${caveAdd.title}</b></div><div>${caveAdd.text}</div></div>`
        };

        item.observation.performer = [
            {
                reference: `Practitioner/${UserService.Practitioner?.id}`,
                display: UserService.UserName
            }
        ];

        item.observation.note = [
            {
                text: caveAdd.text,
                authorReference: {
                    reference: `Practitioner/${UserService.Practitioner?.id}`,
                    display: UserService.UserName
                }
            }
        ];

        item.observation.effectiveDateTime = (caveAdd.targetDate||new Date())?.toJSON();
        item.observation.valueString = caveAdd.title;

        // update observation on fhir
        const fs = new FhirService();
        await fs.update(item.observation);

        item.isLoading = false;
    }

    askDeleteItem(item) {
        const cat = this.i18n.tr('cave_' + item.path);
        let msg = this.i18n.tr("cave_delete", { category: cat, title: item.title});

        DialogMessages.Dialog(this.dialogService, msg, this.i18n.tr("confirm"), this.i18n.tr("delete"), this.i18n.tr("abort"), true )
            .whenClosed(async o=> {
                if (!o.wasCancelled && o.output === true) {
                    try {
                        switch (item.path) {
                            case 'C':
                                this.patient.contraindicationsCount--;
                                break;
                            case 'V':
                                this.patient.valuesCount--;
                                break;
                            case 'E':
                                this.patient.equipmentCount--;
                        }

                        const fs = new FhirService();
                        await fs.delete(item.observation)
                        item.isDeleted = true;
                    }
                    catch (e) {
                        console.warn(e);
                    }
                }
            })
            .catch(e => console.warn(e));
    }

    editItem(item) {
        const cat = this.i18n.tr('cave_' + item.path);
        this.dialogService.open({
            viewModel: ModalCaveAdd,
            model: {
                caption: this.i18n.tr("cave_edit", { category: cat }),
                title: item.title,
                text: item.text,
                targetDate: item.when
            },
            lock: true
        })
            .whenClosed(e => {
                if (e.wasCancelled)
                    return;

                this.updateItem(item, e.output)
                    .catch(e => console.warn(e));
            })
            .catch(e => console.warn(e));
    }
}